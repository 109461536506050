import React from "react"
import { graphql } from "gatsby"
import css from "./css/works-post.module.scss"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import Header from "../components/header"
import Img from "gatsby-image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import Doc from "../components/Doc"

export default function BlogPost({ data }) {
  const post = data.markdownRemark

  const headSet = (
    <div className={css.headSet}>
      <h1>{post.frontmatter.title}</h1>
      <p className={css.medium}>
        {post.frontmatter.medium} / {post.frontmatter.size}{" "}
        <small> {post.frontmatter.sizeUnit}</small>
      </p>
    </div>
  )

  // let isEnglish = data.markdownRemark.fields.langKey === `en`
  let pageTitle =
    `DOWNLOAD: ` + post.frontmatter.title + ` by ` + post.frontmatter.artistName
  let metaOgImage = post.frontmatter.thumbImg.childImageSharp.fluid.src

  let copyrightHolder = post.frontmatter.copyright
    ? post.frontmatter.copyright
    : post.frontmatter.artistName

  // console.log(copyrightHolder)

  let strCopyright = <span>&copy; {copyrightHolder}</span>
  if (post.frontmatter.licence) {
    if (post.frontmatter.licenceLinkTo) {
      strCopyright = (
        <div>
          <span>&copy;{copyrightHolder}</span>,{" "}
          <a href={post.frontmatter.licenceLinkTo} target={`_blank`}>
            {post.frontmatter.licence}
          </a>
        </div>
      )
    } else {
      strCopyright = (
        <div>
          <span>&copy;{copyrightHolder}</span>,{" "}
          <span> {post.frontmatter.licence}</span>
        </div>
      )
    }
  } else {
    strCopyright = (
      <div>
        <span>&copy;{copyrightHolder}</span>
      </div>
    )
  }

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div className={`tbModalContent`}>
          {modal ? (
            <div className={css.closeBox}>
              <Link to={closeTo}>Close</Link>
            </div>
          ) : (
            <Header siteTitle={`TRANS BOOKS DOWNLOADs`} />
          )}

          <SEO title={pageTitle} ogImagePath={metaOgImage} />
          <Doc>
            <div className={css.spHead + ` only-sp`}>{headSet}</div>
            <div className={css.base}>
              <div className={css.baseImg}>
                {post.frontmatter.images.map((src, index) => (
                  <Img fluid={src.childImageSharp.fluid} key={index} />
                ))}
              </div>
              <section className={css.baseContent}>
                <div className="only-pc">
                  <div className={css.baseContent__head}>{headSet}</div>
                </div>

                <div className={css.doc}>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                  <div className={css.copyright}>{strCopyright}</div>
                </div>

                {post.frontmatter.gumroadLinkTo !=="" && (
                  <a
                    href={`${post.frontmatter.gumroadLinkTo}?wanted=true`}
                    className={css.buyBoxButton}
                    data-gumroad-single-product="true"
                  >
                    <span className={css.buyBoxButton__price}>
                      {post.frontmatter.price}
                    </span>
                    <span className={css.buyBoxButton__download}>DOWNLOAD</span>
                  </a>
                )}
                <h2
                  className={css.sectionHead + ` ` + css.sectionHeadIsProfile}
                >
                  {post.frontmatter.artistName}
                </h2>
                <p>
                  <small>{post.frontmatter.artistTitle}</small>
                </p>
                <div className={css.doc + ` ` + css.profile}>
                  <ReactMarkdown source={post.frontmatter.artistProfile} />
                </div>
                <hr />
                {post.fields.langKey === `ja` ? (
                  <ul className={css.notice}>
                    <li>
                      <small>
                        Gumroad
                        による米ドル換算の決済となるため、引き落とし額が円換算時の端数によって前後する場合があります。
                      </small>
                    </li>
                    <li>
                      <small>料金は税込みです</small>
                    </li>
                  </ul>
                ) : (
                  <ul className={css.notice}>
                    <li>
                      <small>
                        We use Gumroad for payment system in US dollars. The
                        withdrawal amount may differ from the displayed amount.
                      </small>
                    </li>
                    <li>
                      <small>Tax included.</small>
                    </li>
                  </ul>
                )}
              </section>
            </div>
          </Doc>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        title
        artistName
        artistTitle
        artistProfile
        price
        size
        sizeUnit
        copyright
        medium
        images {
          childImageSharp {
            fluid(maxWidth: 800, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        thumbImg {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 95) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        licence
        licenceLinkTo
        gumroadLinkTo
      }
      fields {
        slug
        langKey
      }
      excerpt
      timeToRead
      html
      id
    }
  }
`
