import React from "react"

import css from "./Doc.module.scss"

const Doc = (props) => (
  <div className={css.cDoc}>
    {props.children}
  </div>
)

export default Doc
